.accordionClosed {
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.15s ease-out;
}
.accordionHeading {
    background-color: white;
    line-height: 2rem;
    font-size: 1.5rem;
    cursor: pointer;
}
.accordionHeadingPadding {
    padding: 10px;
}
.accordionHeading:hover {
    background-color: rgb(173, 214, 253);
}
.accordion {
    margin-top: 30px;
}
.accordionLabel {
    width: 100%;
    cursor: pointer;
    display: inline-block;
}
.accordionOpen {
    max-height: 1000px;
    transition: max-height 1s ease-out;
}
.accordionItem {
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
    border-left: 1px solid gray;
    border-right: 1px solid gray;
    margin-bottom: 30px;
    margin-right: 30px;
}