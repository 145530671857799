.interactionContainer .answer button {
  color: #fff;
  width: 100%;
  margin: 0;
  height: 50px;
  line-height: 50px;
  font-size: 1.5rem;
  cursor: pointer;
  border: none;
  background-color: #26639a;
}
.interactionContainer .correct button {
  background-color: rgb(0, 194, 0) !important;
}
.interactionContainer .incorrect button {
  background-color: rgb(194, 49, 0) !important;
}
.interactionContainer .answer button:hover {
  background-color: #012c53;
  transition: background-color 500ms;
}
.interactionContainer .question {
  font-size: 1.5rem;
  margin-bottom: 20px;
}
.display {
  margin-top: 30px; 
  display: inline-block;
}
.displaySuccess {
  color: rgb(0, 194, 0) !important;
  font-weight: 3rem;
  font-weight: bold;
}
.displayFail {
  color: rgb(194, 49, 0) !important;
  font-weight: 3rem;
  font-weight: bold;
}
.answers {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}
.answers .answer {
  width: calc(50% - 5px);
}