.popUpContainer {
  position: fixed;
  left: 0px; 
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(128, 128, 128, 0.434);
  z-index: 100;
}
.popUpForegroundContainer {
  width: 100%;
  height: 100%;
}
.popUpForeground {
  background-color: black;
  height: 100%;
}
.popUpGrid {
  height: 100%;
}
.popUpTop {
  margin: 0 auto;
  position: relative;
  height: 40px;
}
.popUpBottom {
  height: calc(100% - 40px);
  margin: 0 auto;
}
.popUpBottomVideoPosition {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.popupIcon {
  padding: 5px;
  position: absolute;
  right: 0px;
}
.popupIcon:hover {
  cursor: pointer;
  display: inline-block;
}
@media (max-width: 575.98px) { 
  
}

/* Small devices (landscape phones, less than 768px) */
@media (min-width: 575.99px) and (max-width: 767.98px) {
  
}

/* Medium devices (tablets, less than 992px) */
@media (min-width: 767.99px) and (max-width: 991.98px) {
  .popUpBottom {
    width: 80%;
  }
  .popUpTop {
    width: 80%;
  }
}

/* Large devices (desktops, less than 1200px) */
@media (min-width: 991.99px) and (max-width: 1199.98px) {
  .popUpBottom {
    width: 80%;
  }
  .popUpTop {
    width: 80%;
  }
}

@media (min-width: 1199.98px) {
  .popUpBottom {
    width: 80%;
  }
  .popUpTop {
    width: 80%;
  }
}
