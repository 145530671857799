.validationError input[type="text"], .validationError input[type="email"], .validationError input[type="password"]{
  background-color: rgb(253, 219, 224);
  border: 1px solid rgb(255, 137, 180);
  margin-bottom: 5px;
}
.validationError .asterisk {
  color: rgb(255, 0, 93);
}
.formRow .validationMessage {
  margin: 0px 0px 25px 0px;
  padding: 0px;
  text-align: center;
}
.validationError .validationMessage {
  color: rgb(255, 0, 93);
}