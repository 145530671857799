.questionCorrect input[type="text"] {
  width: calc(100% - 70px) !important;
}
.questionCorrect .checkboxContainer {
  text-align: center;
  display: inline-block;
  width: 70px;
}

.questionLabel {  display: grid;
  grid-template-columns: 1fr 70px;
  grid-template-rows: min-content;
  grid-auto-rows: 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "one two";
  margin-bottom: 15px;
}

.one { grid-area: one; }

.two { 
  grid-area: two; 
  text-align: center; 
}
