.logo {
  font-size: 3rem;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  margin: 0;
  color: white;
  line-height: 100px;
  padding-left: 20px;
}
.customTimeline {
  height: 60px;
  background-color: rgba(26, 26, 26, 0.75);
  width: calc(100%);
  position: relative;
  margin: 0px auto;
}
.customTimeline div {
  width: 3px;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.205);
  color: black;
  height: 80%;
  top: 10%;
}
.customControlsContainer {
  display: flex;
  align-items: center;
  z-index: 1;
  pointer-events: none;
  position: relative;
}
.timelineButton {
  height: 60px;
  width: 30px;
  text-align: center;
  line-height: 60px;
  position: absolute;
  z-index: 2;
  color: white;
  font-size: 30px;
  pointer-events: auto;
  cursor: pointer;
  background-color: #ffffff36;
}
.noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.timelineButton:hover {
  background-color: white;
  color: black;
}
.timelineNext {
  right: 0px;
}

.displayAnswer {
  font-size: 2rem;
}

.interactionContainer {
  text-align: center;
  background-color: black;
  color: white;
  width: 100%;
  pointer-events: auto;
}
.interactionContainer button { 
  border: none;
  font-size: 3rem;
  width: 200px;
  color: white;
  cursor: pointer;
}
.interactionContainer .button-correct {
  background-color: rgb(0, 190, 95);
  margin-right: 30px;
}
.interactionContainer .button-incorrect {
  background-color: rgb(190, 0, 0);
}
.correctness {
  margin-top: 0px;
}
.embedContainer {
  overflow: hidden;
  position: relative;
  width:100%;
}
.myPlayer {
  background-color: black;
  width: calc(100% - 100px);
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
  pointer-events: auto;
}
.myPlayer .play {
  color: white;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 20px;
}
.myPlayer p {
  color: white;
  font-size: 2rem;
}
.embedContainer::after {
  padding-top: 56.25%;
  display: block;
  content: '';
}

.embedContainer iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.timesContainer {
  list-style: none;
  margin: 0px;
  padding: 0px;
}
.timesContainer li {
  height: 70x;
  line-height: 3rem;
  margin: 0px;
  padding: 0px;
  color: white;
  font-size: 1.2rem;
  text-align: center;
  border-bottom: 1px dashed black;
}

.past {
  background-color: #5092c9 !important;
}

.breakButton {
  font-size: 2rem;
  padding: 0px 15px;
  line-height: 60px;
}

.watchDescription {
  padding: 30px 30px 0px 30px;
}

.cleanContainer .flashStatus {
  margin-left: 30px;
  margin-right: 30px;
}
.cleanContainer > p {
  margin: 30px 30px 0px 30px;
}

.cleanContainer h1 {
  margin-top: 0px;
}

.container {  display: grid;
  grid-template-columns: 1.7fr 0.5fr  1.2fr;
  grid-template-rows: auto auto 100px auto 30px;
  gap: 0px 0px;
  margin-bottom: 100px !important;
  grid-auto-flow: row;
  grid-template-areas:
    "videoInput videoInput videoInput"
    "videoContainer videoContainer videoContainer"
    "uiContainer uiContainer uiContainer"
    "logoContainer logoContainer scrollContainer"
    "gridFooter gridFooter gridFooter";
}
.gridFooter {
  grid-area: gridFooter;
}

.flashStatus {
  margin-bottom: 25px;
}
.foregroundContainer {
  padding-top: 15px;
  grid-area: videoInput;
  vertical-align: bottom;
}
.foregroundContainer input {
  background: white;
  border: 1px solid rgb(173, 214, 253);
  height: 65px;
  width: calc(100%);
  display: inline-block;
  color: black;
}
.foregroundContainer button {
  display: inline-block;
  position: relative;
  top: 3px;
  margin-left: 15px;
}

.menuContainer { 
  grid-area: menuContainer; 
  background-color: black;
  color: white;
}

.logoContainer { 
  grid-area: logoContainer; 
}

.myTimeChoice {
  cursor: pointer !important;
}

.selected {
  background-color: white !important;
  color: black !important;
}
.videoForm input[type="text"] {
  background-color: white;
  border: 1px solid #acd7fb;
  width: 100%;
  color: black;
  margin-bottom: 15px;
}
.videoContainerContainer {
  grid-area: videoContainer;
}
.videoContainer, .embedUi { 
  display: flex;
  align-items: center;
}
.embedUi {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 5;
  flex-direction: column;
  justify-content: center;
  background-color: black;
}
.scrollContainer { 
  grid-area: scrollContainer;
  background-color: hsl(207, 100%, 21%);
  min-height: 300px;
}

.uiContainer { 
  grid-area: uiContainer; 
  background-color: rgb(173, 214, 253);
  display: flex;
  align-items: center;
  padding: 0px 20px;
  justify-content: space-between;
}
.timer h3 {
  color: #003b6b;
  background-color: transparent;
}
.contactForm {
  padding-top: 15px;
}
.contactForm > .flashStatus {
  margin-bottom: 0px;
  margin-left: 15px;
  margin-right: 15px;
  padding: 5px;
}
.statusIcon {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
}
.statusIcon button {
  cursor: pointer;
  border: none;
  padding: 10px 15px;
  font-size: 2rem;
}
.statusError button {
  background-color: #c55353;
  color: white;
}
.statusNet button {
  background-color: #000000;
  color: white;
}
.prettyTable {
  background-color: white;
  border-collapse: collapse;
  width: 100%;
}
.prettyTable tbody td {
  padding: 10px;
}
.prettyTable th {
  background-color: #5aa8eb;
  color: white;
  font-weight: bold;
  font-size: 2rem;
}
.prettyTable tr:nth-child(even) {
  background-color: #e4e4e4;
}
.clickableRows {
  cursor: pointer;
}
.clickableRows tr:hover {
  background-color: black;
  color: white;
}
.clickableRows tr:hover a:link, .clickableRows tr:hover a:visited {
  color: white;
}
.smallCol {
  max-width: 100px;
}
.tleft {
  text-align: left;
}
.tcenter {
  text-align: center;
}
.tright {
  text-align: right;
}
.formContainer {
  background-color: white;
  width: 600px;
  padding: 25px;
  margin: 0px auto;
}
.formContainer ul {
  list-style:none;
  padding: 0;
  margin: 0;
}
.formContainer input[type="text"], .formContainer input[type="email"], .formContainer input[type="password"] {
  background-color: #e5f5ff;
  color: black;
  height: 60px;
  line-height: 60px;
  border-left: 1px solid #91d3fb;
  border-top: 1px solid #91d3fb;
  border-bottom: 1px solid #91d3fb;
  border-right: 1px solid #91d3fb;
  color: black;
  font-size: 1.5rem;
  padding-left: 10px;
  width: 100%;
  text-align: left;
  margin-bottom: 25px;
}
.formContainer label {
  min-width: 100px;
  display: block;
}
.formContainer button, .colorButton {
  background-color: #5aa8eb;
  color: #fff;
  width: 100%;
  margin: 0;
  height: 50px;
  line-height: 50px;
  font-size: 1.5rem;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  margin-bottom: 25px;
}
.signupPage, .loginPage {
  padding-bottom: 60px;
  padding-top: 60px;
}
.noMarginBottom {
  margin-bottom: 0px !important;
}
.grayButton {
  background-color: #9f9f9f !important;
}
.blackButton {
  background-color: black !important;
}
.videoForm {
  width: 100%;
  margin-bottom: 35px;
  margin-top: 15px;
}
.marginLeft {
  margin-left: 15px;
}
.marginBottom {
  margin-bottom: 30px !important;
}
.paddingBottom {
  padding-bottom: 30px !important;
}
.lightInput {
  background-color: white !important;
  border: 1px solid #acd7fb !important;
  color: black !important;
  margin-bottom: 25px;
}
.redButton {
  background-color: #c55353;
}
.smallButton {
  width: auto;
  min-width: 200px;
}
.noMarginTop {
  margin-top: 0px;
}
.confirmSection {
  background-color: white;
  padding: 15px;
  text-align: center;
}
.radioList label {
  display: inline-block;
  min-width: 80px;
  padding: 10px;
  margin-bottom: 15px;
}
.radioList .success {
  background-color: rgb(153, 204, 102);
}
.radioList .caution {
  background-color: rgb(204, 102, 102);
}

.foregroundSection {
  background-color: white;
  padding: 15px;
}
.formContainer h2 {
  margin-bottom: 20px;
}
.formContainer span {
  color: black;
  font-size: 1rem;
}
.textPage {
  position: relative;
  top: 35px;
}

.termsInput {
  margin-top: 0px;
  margin-bottom: 30px;
  vertical-align: middle;
}
input[type="checkbox"] {
  height: 20px;
  width: 20px;
}
nav ul .active {
    background-color: rgba(0, 0, 0, 0.4);
    border-left: 8px solid #eb8934;
    animation: borderHover 0.2s;
    animation-timing-function: ease-in;
}