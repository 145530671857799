.iconButton {
  display: inline-block;
  height: 115px;
  width: 115px;
  background-color: rgb(45, 45, 45);
  border: 1px solid rgb(97, 97, 97);
}
.iconButton > div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.iconButton:hover {
  background-color: rgb(95, 94, 94);
  transition: background-color 300ms;
}
.myPlayer .play .iconButton:first-child{
  margin-right: 30px;
}