* {
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
html, body {
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: sans-serif;
  background-color: #e5f5ff;
}
body {
  position: relative;
}
@keyframes moveDown {
  0% {
      top: -50px;
  }
  100% {
      top: 20px;
  }
}
@keyframes reveal {
  0% {
      opacity: 0.3;
      transform: translateY(15px);
  }
  100% {
      opacity: 1.0;
      transform: translateY(0px);
  } 
}
@keyframes borderHover {
  0% {
      border-width: 0px;
  }
  100% {
      border-width: 8px;
  }
}
@keyframes galleryReveal {
  0% {
      transform: scale(0.5);
  }
  100% {
      transform: scale(1);
  }
}
@keyframes innerPadding {
  100% {
      padding-left: 20px;
      padding-right: 20px;
  }
}
#logo {
  position: absolute;
  animation: moveDown 1s;
  animation-fill-mode: both;
  z-index: 15;
}
nav {
  height: 100vh;
  width: 300px;
  position: fixed;
  background-color: #140f1f;
}
nav .boundry {
  height: 100%;
  width: 100%;
  position: relative;
}
nav ul {
  color: white;
  list-style: none;
  padding: 0;
  z-index: 10;
  position: absolute;
  top: 100px;
  width: 100%;
}
nav ul li {
  font-size: 2rem;
  width: 100%;
}
nav ul a {
  display: block;
  padding: 15px;
  width: 100%;
  text-shadow: 3px 3px 4px rgba(0, 0, 0, 1);
}
nav ul a:link {
  color: white;
  text-decoration: none;
}
nav ul .active a {
  background-color: rgba(0, 0, 0, .4);
  border-left: 8px solid #ff8d8d;
  animation: borderHover 0.2s;
  animation-timing-function: ease-in;
}
#soften-video {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  background-color: rgb(0, 0, 0);
  z-index: 8;
  transition: opacity 500ms;
  transition-timing-function: ease;
  opacity: 0.5;
}
#nav-video {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 1;
  object-fit: cover;
}
@media(hover: hover) and (pointer: fine) {
  nav ul a:hover {
      background-color: rgba(0, 0, 0, 0.4);
      border-left: 8px solid #ff8d8d;
      animation: borderHover 0.2s;
      animation-timing-function: ease-in;
  }
}
nav ul a:visited {
  color: white;
}
nav ul li span {
  display: block;
  font-size: 1rem;
}
/*
font-family: 'Arima Madurai', cursive;
font-family: 'Bebas Neue', cursive;
font-family: 'Playfair Display', serif;
font-family: 'Secular One', sans-serif;
*/
#logo {
  font-family: 'Secular One', sans-serif;
  text-align: center;
  margin-top: 0;
  font-size: 2rem;
  color: black;
  width: calc(100% - 40px);
}
#logo h1 {
  margin: 0;
  padding: 0;
  line-height: 0;
  margin-top: 30px;
}
.tagline {
  font-size: 1.5rem;
  line-height: 0;
  margin-top: 10px;
  margin: 0;
  padding: 0;
  font-family: 'Secular One', sans-serif;
  color: #774a61;
}
.logoInnerContainer {
  display: inline-block;
  background-color: #ff8d8d;
  padding: 8px;
  border-radius: 8px;
}

header {
  position: relative;
  padding-top: 20px;
}
.boundry .mic {
  position: absolute;
  right: 15px;
  bottom: 15px;
  color: white;
  font-size: 4rem;
  z-index: 15;
}
#mic-leed {
  position: absolute;
  right: 34px;
  width: 5px;
  background-color: #eb8934;
  height: 40px;
  top: 0px;
  z-index: 15;
}
#mic-leed-boundry {
  height: 100%;
  width: 100%;
  position: relative;
}
#mic-plug {
  position: absolute;
  bottom: 0px;
  background-color: white;
  height: 20px;
  width: 9px;
  left: -2px;
}
.container {
  margin-left: 30px;
  margin-right: 30px;
}
#features {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
main {
  margin-left: 300px;
  width: calc(100% - 300px);
  background-color: #e5f5ff;
  box-shadow: -5px -20px 20px 5px rgb(98 108 102);
  position: relative;
}
main h2 {
  color: #5aa8eb;
  margin-top: 0px;
  margin-bottom: 0px;
}
main .top-heading {
  margin-top: 40px;
}
main .top-heading u {
  text-decoration-color: black;
  text-decoration-thickness: 2px;
}
.faint {
  color: rgba(0, 0, 0, 0.5)
}
main h3 {
  text-align: center;
  font-size: 3rem;
  margin-top: 30px;
  margin-bottom: 30px;
  background-color: white;
}
.three {
  margin-bottom: 30px;
}
.three .square {
  position: relative;
  width: calc(33.333% - 22px);
  float: left;
  border-bottom: 2px solid #5aa8eb52;
  border-right: 2px solid #5aa8eb52;
}
.three .observed:nth-child(1) {
  animation: reveal 0.75s;
  animation-fill-mode: both;
  animation-delay: 0.25s;
}
.three .observed:nth-child(2) {
  animation: reveal 0.75s;
  animation-fill-mode: both;
  animation-delay: 0.5s;
}
.three .observed:nth-child(3) {
  animation: reveal 0.75s;
  animation-fill-mode: both;
  animation-delay: 0.75s;
}
.three .square:not(:first-child) {
  margin-left: 33px;
}
.three .square:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
.tagText {
  text-align: center;
  font-size: 2rem;
  padding-top: 15px;
  padding-bottom: 15px;
}
.callToAction {
  padding-bottom: 30px;
}
.three .content {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 50%;
  margin-top: -80px;
  padding-left: 10px;
  padding-right: 10px;
  color: black;
}
.three .content h4 {
  margin-top: 10px;
  font-size: 2rem;
  color: black;
  margin-bottom: 10px;
}
#top-podcasts {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
#top-podcasts .wrapper {
  margin-top: -65px;
}
.gallery {
  line-height: 0px;
  position: relative;
}
.gallery .arrow:hover {
  cursor: pointer;
}
.gallery .leftArrow {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 50px;
  background-color: #eb8934b5;
  z-index: 2;
}
.gallery .leftArrow i {
  top: 50%;
  left: 50%;
  margin-top: -25px;
  margin-left: -25px;
  color: white;
  position: absolute;
  font-size: 3rem;
}
.gallery .rightArrow {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 50px;
  background-color: #eb8934b5;
  z-index: 2;
}
.gallery .rightArrow i {
  top: 50%;
  left: 50%;
  margin-top: -25px;
  margin-left: -20px;
  color: white;
  position: absolute;
  font-size: 3rem;
}
.clear {
  clear: both;
}
.three i {
  font-size: 4rem;
}
.three .email {
  position: relative;
  bottom: 4px;
}
.panel {
  padding-top: 15px;
  margin-top: 30px;
  padding-bottom: 60px;
}
.whitePanel {
  background-color: white;
  border-top: 2px dashed #ff8d8d;
  min-height: 100vh;
}
.blackPanel {
  background-color: black;
}
.lighterPanel {
  background-color: #ffac68;
}
.grayPanel {
  background-color: #515151;
  margin-top: 0px;
  border-top: 3px solid black;
  min-height: 100vh;
}
.grayPanel h3 {
  color: white;
}
.newsletterForm {
  text-align: center;
}
.newsletterContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.list {
  margin-top: 30px;
  color: white;
  display: grid;
  grid-template-columns: 40px 1fr;
  line-height: 3rem;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}
.list .colIcon {
  grid-column-start: 1;
  grid-column-end: 2;
  justify-self: end;
}
.list .colText {
  grid-column-start: 2;
  grid-column-end: 3;
  padding-left: 10px;
}
.check {
  font-size: 2rem;
  vertical-align: middle;
  margin-right: 5px;
}
.secondCheck {
  margin-left: 5px;
}
input[type="email"], input[type="text"] {
  height: 60px;
  line-height: 60px;
  background-color: black;
  border-left: 1px solid white;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  border-right: none;
  color: white;
  font-size: 1.5rem;
  padding-left: 10px;
  width: 50%;
  text-align: center;
}
.foregroundSection input[type="text"] {
  width: 100%;
}
input[type="submit"] {
  background-color: white;
  height: 60px;
  line-height: 60px;
  font-size: 2rem;
  border: none;
  color: black;
  text-transform: uppercase;
  font-family: Helvetica, sans-serif;
  font-weight: bold;
  position: relative;
  top: 4px;
  margin-left: -3px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}
input[type="submit"]:hover {
  transition: padding-left 200ms, padding-right 200ms;
  transition-timing-function: ease-in;
  padding-left: 20px;
  padding-right: 20px;
}
textarea:focus, input:focus{
  outline: none;
}
#newsletterForm.observed {
  animation: backAndForth 100ms ease;
  animation-direction: alternate;
  animation-iteration-count: 4;
  animation-delay: 1s;
}
.observed #subscriberEmail {
  animation: borderColor 100ms ease;
  animation-direction: alternate;
  animation-iteration-count: 4;
  animation-delay: 1s;
}
.observed #subscribeButton {
  animation: orangeButton 200ms ease 1s 2 alternate both;
}
@keyframes orangeButton {
  100% {
      background-color: #eb8934;
  }
}
@keyframes backAndForth {
  0% {
      transform: translateX(-20px);
  }
  100% {
      transform: translateX(20px);
  }
}
@keyframes borderColor {
  0% {
      border-top: 1px solid white;
      border-bottom: 1px solid white;
      border-left: 1px solid white;
  }
  100% {
      border-top: 1px solid #eb8934;
      border-bottom: 1px solid #eb8934;
      border-left: 1px solid #eb8934;
  }
}
@keyframes visible {
  0% {
      opacity: 0;
      transform: scale(0.75);
  }
  100% {
      opacity: 100;
      transform: scale(1);
  }
}
.hidden {
  display: none;
}
.visible {
  display: block;
  animation: visible 1s ease-out;
  animation-fill-mode: both;
}
.flash {
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 35px;
}
.flash span {
  height: 80px;
  line-height: 80px;
  vertical-align: middle;
}
.flash pre {
  text-align: left;
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  margin: 0px;
  padding: 15px;
  font-size: 1rem;
}
.flash-success {
  background-color: #b2ffb7;
  border: 1px solid #5ac962;
}
.flash-success span {
  border-bottom: 1px solid #5ac962;
}
.flash-fail {
  background-color: #ffafaf;
  border: 1px solid #db2929;
}
.flash-fail span {
  border-bottom: 1px solid #db2929;
}
.flash-fail pre {
  color: #db2929;
  margin-top: -30px;
}
@keyframes moveBox {
  0% {
      transform: translateY(500px);
  }
  100% {
      transform: translateY(0px);
  }
}
#contact {
  background-image: url('./images/pexels-irina-iriser-1083809.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.opaqueBorder {
  background-color: rgba(255,255,255,0.3);
  height: 8px;
}
#contact .form {
  background-color: rgba(255,255,255,0.5);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0px 0px 20px 20px rgba(0, 0, 0, 0.5);
  padding-bottom: 30px;
}
#contact .boundry {
  width: 60%;
}
#contact.observed .boundry {
  animation: moveBox 500ms ease 500ms 1 forwards;
  animation-fill-mode: both;
}
#contact h3 {
  color: #eb8934;
}
#contact .inputWrapper, #contact .textareaWrapper {
  margin: 45px 15px 0px 15px;
  position: relative;
}
#contact textarea {
  outline: none;
  resize: none;
  overflow: auto;
  padding: 10px;
  font-family: sans-serif;
  font-size: 1.5rem;
}
#contact input[type="text"], #contact input[type="email"], #contact textarea {
  border: none;
  background-color: white;
  color: black;
  border-radius: 10px;
  text-align: left;
  width: 100%;
  transition: background-color 100ms;
}
.form-button {
  background-color: rgba(0,0,0,1);
  color: white;
  width: 100%;
  margin: 0;
  border-radius: 10px;
  height: 60px;
  line-height: 60px;
  font-size: 2rem;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
}
.form-button:hover {
  transition: background-color 500ms;
  background-color: #ff8d8d;
}
.form-button.success {
  transition: background-color 500ms;
  background-color: #00a63a;
}
.form-button.error {
  transition: background-color 500ms;
  background-color: #a31616;
}
#contact .hasFocus label {
  transform: translateY(-40px);
  color: white;
  transition: transform 500ms, color 500ms;
}
#contact label {
  color: #eb8934;
  text-transform: uppercase;
  position: absolute;
  left: 10px;
  bottom: 0px;
  font-weight: bold;
  height: 60px;
  line-height: 60px;
  font-size: 2rem;
  pointer-events: none;
}
#contact .textareaWrapper label {
  bottom: auto;
  top: 0px;
}
#contact .twoCol {
  display: inline-block;
  width: calc(50% - 22px);
}
#contact .twoCol:first-of-type {
  margin-right: 15px;
}
#contact .twoCol:last-of-type {
  margin-right: 0px;
  margin-left: 0px;
}
@media (max-width: 575.98px) { 
  nav {
      width: 100%;
      position: relative;
  }
  main {
      margin-left: 0px;
      width: 100%;
  }
  main h2 {
      font-size: 1rem;
  }
  main h3 {
      font-size: 1.5rem;
  }
  .three .square {
      width: calc(100%);
  }
  .three .square:not(:first-child) {
      margin-left: 0px;
  }
  .three .square:not(:last-child) {
      margin-bottom: 15px;
  }
  .container {
      margin-left: 15px;
      margin-right: 15px;
  }
  input[type="text"], input[type="email"] {
      display:block;
      border-right: 1px solid white;
      width: 100%;
  }
  input[type="submit"] {
      margin-top: 15px;
      width: 100%;
  }
  .newsletterForm {
      text-align: left;
  }
  .list {
      width: 100%;
  }
  #contact .boundry {
      width: 100%;
  }
  #contact label {
      font-size: 1rem;
  }
  #top-podcasts .wrapper {
      margin-top: 0px;
  }
  .breakButton {
    line-height: normal;
    padding: 10px 5px;
    font-size: 1.5rem;
  }
  .formContainer {
    width: 100%;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (min-width: 575.99px) and (max-width: 767.98px) {
  nav {
      width: 230px;
  }
  nav ul li {
      font-size: 1.5rem;
  }
  nav ul li span {
      font-size: 0.8rem;
  }
  main {
      margin-left: 230px;
      width: calc(100% - 230px)
  }
  main h2 {
      font-size: 1rem;
  }
  main h3 {
      font-size: 1.5rem;
  }
  .three .square {
      width: calc(100%);
  }
  .three .square:not(:first-child) {
      margin-left: 0px;
  }
  .three .square:not(:last-child) {
      margin-bottom: 15px;
  }
  .container {
      margin-left: 15px;
      margin-right: 15px;
  }
  .formContainer {
    width: 100%;
  }
  input[type="text"], input[type="email"] {
      display:block;
      width: 100%;
      border-right: 1px solid white;
  }
  input[type="submit"] {
      margin-top: 15px;
      width: 100%;
  }
  .newsletterForm {
      text-align: left;
  }
  .list {
      width: 100%;
  }
  #top-podcasts .wrapper {
      margin-top: 0px;
  }
  #contact div label {
      font-size: 1rem;
  }
  #contact .boundry {
      width: calc(100% - 30px);
  }
}

/* Medium devices (tablets, less than 992px) */
@media (min-width: 767.99px) and (max-width: 991.98px) {
  nav {
      width: 230px;
  }
  nav ul li {
      font-size: 1.5rem;
  }
  nav ul li span {
      font-size: 0.8rem;
  }
  main {
      margin-left: 230px;
      width: calc(100% - 230px)
  }
  main h2 {
      font-size: 1rem;
  }
  main h3 {
      font-size: 1.5rem;
  }
  .three i {
      font-size: 2.5rem;
  }
  .three p {
      font-size: 0.75rem;
  }
  .three .content {
      margin-top: -60px;
  }
  .three .content h4 {
      font-size: 1rem;
  }
  .three .square {
      width: calc(33.333% - 10px);
  }
  .three .square:not(:first-child) {
      margin-left: 15px;
  }
  .container {
      margin-left: 15px;
      margin-right: 15px;
  }
  .formContainer {
    width: 100%;
  }
  .list {
      width: 80%;
  }
  #contact div label {
      font-size: 1rem;
  }
  #contact .boundry {
      width: calc(100% - 120px);
  }
}

/* Large devices (desktops, less than 1200px) */
@media (min-width: 991.99px) and (max-width: 1199.98px) {
  .three i {
      font-size: 2.5rem;
  }
  .three p {
      font-size: 0.75rem;
  }
  .three .content {
      margin-top: -60px;
  }
  .three .content h4 {
      font-size: 1rem;
  }
  #contact label {
      font-size: 1.5rem;
  }
  #contact .boundry {
      width: calc(100% - 240px);
  }
  .list {
      width: 80%;
  }
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#confetti {
    width: 100%;
    height: 100%;
    position: fixed;
}